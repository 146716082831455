import { PriceOfferShort } from '../../../../services/api/price-offer/PriceOffer.types'
import { Row } from '@tanstack/react-table'
import { usePriceOfferTableContext } from './PriceOfferTable.context'
import PriceOfferTableDefaultVariant from './PriceOfferTableDefaultVariant'
import PriceOfferTableVariant from './PriceOfferTableVariant'
import React, { useMemo } from 'react'

export type PriceOfferTableNameCellProps = {
    row: Row<PriceOfferShort>
}

const DEFAULT_VARIANT = '0'

const PriceOfferTableNameCell = ({ row: { original: priceOffer } }: PriceOfferTableNameCellProps) => {
    const { id, number, name } = priceOffer
    const { getSelectedVariant, selectVariant } = usePriceOfferTableContext()
    const selectedVariant = useMemo(() => {
        return getSelectedVariant(id, [priceOffer, ...priceOffer?.variants])
    }, [getSelectedVariant, id, priceOffer])

    const onSelect = (variant: string) => {
        return selectVariant(id, variant)
    }

    const sortedAlphabeticallyPriceOfferVariant = priceOffer.variants.sort((a, b) => {
        return a.variant.localeCompare(b.variant)
    })
    const hasVariants = priceOffer.variants.length === 0

    return (
        <fieldset
            className='flex flex-col gap-2'
            onClick={event => {
                return event.stopPropagation()
            }}
        >
            {!hasVariants ? (
                <div className='ml-1.5'>
                    <PriceOfferTableDefaultVariant
                        rowId={id}
                        variant={DEFAULT_VARIANT}
                        selected={selectedVariant === DEFAULT_VARIANT}
                        onSelect={onSelect}
                        label={
                            <div className='flex flex-col'>
                                {name && <strong>{name}</strong>}
                                <span>{number}</span>
                            </div>
                        }
                    />
                </div>
            ) : (
                <div className='ml-1.5'>
                    <div className='flex flex-col '>
                        {name && <strong>{name}</strong>}
                        <span>{number}</span>
                    </div>
                </div>
            )}

            <div className='inline-flex flex-col gap-1'>
                {sortedAlphabeticallyPriceOfferVariant.map(item => {
                    return (
                        <PriceOfferTableVariant
                            key={item.variant}
                            rowId={id}
                            variant={item.variant}
                            selected={selectedVariant === item.variant}
                            onSelect={onSelect}
                            label={item.name && <strong>{item.name}</strong>}
                        />
                    )
                })}
            </div>
        </fieldset>
    )
}

export default PriceOfferTableNameCell
