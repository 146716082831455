import { truncateStringWithData } from '../../../utils/StringHelper'
import React, { useMemo } from 'react'
import Tooltip from '../tooltip/Tooltip'

export type TruncateTooltipProps = {
    maxLength?: number
    text: string
}

const TruncateTooltip = ({ maxLength = 20, text }: TruncateTooltipProps) => {
    const truncatedText = useMemo(() => {
        return truncateStringWithData(text, maxLength)
    }, [maxLength, text])

    if (!truncatedText.truncated) {
        return <>{text}</>
    }

    return <Tooltip content={text}>{truncatedText.text}</Tooltip>
}

export default TruncateTooltip
