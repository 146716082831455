import { ThemeProps } from '../theme/ThemeProps'
import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import Badge from '../badge/Badge'
import React, {
    AnchorHTMLAttributes,
    ButtonHTMLAttributes,
    ForwardedRef,
    PropsWithChildren,
    ReactNode,
    forwardRef
} from 'react'

export type PlainTabProps = ButtonHTMLAttributes<HTMLButtonElement> &
    AnchorHTMLAttributes<HTMLAnchorElement> &
    ThemeProps & {
        link?: boolean
        active?: boolean
        icon?: ReactNode
        grow?: boolean
        badge?: ReactNode
    }

const PlainTab = (
    {
        children,
        link = false,
        color = 'primary',
        active = false,
        icon,
        grow = false,
        className,
        href = '',
        badge,
        ...props
    }: PropsWithChildren<PlainTabProps>,
    ref: ForwardedRef<HTMLButtonElement | HTMLAnchorElement>
) => {
    const renderIcon = () => {
        return icon && <span className='plain-tab-icon'>{icon}</span>
    }

    const renderContent = () => {
        return (
            <>
                {renderIcon()}
                <span>{children}</span>
                <span aria-hidden='true' className='plain-tab-line' />
                {badge && (
                    <Badge color='primary' className='ml-1'>
                        {badge}
                    </Badge>
                )}
            </>
        )
    }

    const classes = buildClassesWithDefault(
        {
            'plain-tab': true,
            active: active,
            group: true,
            [color]: true,
            grow: grow
        },
        className
    )

    if (link) {
        return (
            <a
                {...(props as AnchorHTMLAttributes<HTMLAnchorElement>)}
                className={classes}
                href={href}
                ref={ref as ForwardedRef<HTMLAnchorElement>}
                aria-current={active ? 'page' : undefined}
            >
                {renderContent()}
            </a>
        )
    }

    return (
        <button
            {...(props as ButtonHTMLAttributes<HTMLButtonElement>)}
            className={classes}
            ref={ref as ForwardedRef<HTMLButtonElement>}
            aria-current={active ? 'page' : undefined}
        >
            {renderContent()}
        </button>
    )
}

PlainTab.displayName = 'PlainTab'

export default forwardRef(PlainTab)
