import { useTranslation } from 'next-i18next'

import { PriceOfferOrderInfo, PriceOfferShort } from '../../../../services/api/price-offer/PriceOffer.types'
import Button from '../../../base/button/Button'
import Modal, { ModalProps } from '../../../base/modal/Modal'
import ModalText from '../../../base/modal/ModalText'
import React from 'react'

export type PriceOfferAddVariantDialogProps = Omit<ModalProps, 'show'> & {
    show?: boolean
    onClose?: () => void
    variant: string
    orders: PriceOfferOrderInfo[]
    confirm: () => void
    confirmConnect: () => void
    loading: boolean
    name: string
    numberOfPriceOffer: string
    variants: PriceOfferShort[]
}

const PriceOfferAddVariantDialog = ({
    onClose,
    show,
    confirm,
    confirmConnect,
    loading
}: PriceOfferAddVariantDialogProps) => {
    const { t } = useTranslation('common')

    const renderActions = () => {
        return (
            <div className='flex  flex-col gap-2 w-[50%] '>
                <Button variant='normal' onClick={confirm} loading={loading}>
                    {t('frontend.dashboard.demand.table.actions.copy')}
                </Button>
                <Button variant='normal' onClick={confirmConnect} loading={loading}>
                    {t('frontend.dashboard.demand.table.actions.only_connect')}
                </Button>
                <Button variant='outlined' onClick={onClose}>
                    {t('frontend.price_offers.create.demands_select.cancel')}
                </Button>
            </div>
        )
    }

    return (
        <Modal show={show}>
            <ModalText>
                <div className='text-subtitle-small font-normal text-gray text-center mb-4'>
                    {t('frontend.dashboard.demand.table.new_variant_copy_or_connect_to_new_variant')}
                </div>
                <div className='text-subtitle-smaller font-normal text-gray text-center mb-4'>
                    {t('frontend.dashboard.demand.table.new_variant_copy_or_connect_to_new_variant.additional_info')}
                </div>
                <div className='flex justify-center mt-6'>{renderActions()}</div>
            </ModalText>
            <div></div>
        </Modal>
    )
}

export default PriceOfferAddVariantDialog
