import { ThemeProps } from '../theme/ThemeProps'
import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import { deepFind } from 'react-children-utilities'
import { getFunctionName } from '../../../utils/StringHelper'
import React, { HTMLAttributes, PropsWithChildren } from 'react'

export type PlainTabsProps = Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> & ThemeProps & {}

const PlainTabs = ({ children, color = 'primary', className, ...props }: PropsWithChildren<PlainTabsProps>) => {
    const renderTabs = () => {
        return deepFind(children, child => {
            return React.isValidElement(child) && getFunctionName(child.type) === 'PlainTabList'
        })
    }

    return (
        <div {...props} className={buildClassesWithDefault({ 'plain-tabs': true, [color]: true }, className)}>
            <div>
                <div>
                    <nav aria-label='Tabs'>{renderTabs()}</nav>
                </div>
            </div>
        </div>
    )
}

PlainTabs.displayName = 'PlainTabs'

export const PlainTabList = ({ children }: PropsWithChildren<any>) => {
    return <>{children}</>
}

PlainTabList.displayName = 'PlainTabList'

export default PlainTabs
