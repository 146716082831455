/* eslint-disable max-len */
import { ThemeProps } from '../theme/ThemeProps'
import React, {
    ForwardedRef,
    InputHTMLAttributes,
    MutableRefObject,
    ReactNode,
    forwardRef,
    useEffect,
    useId,
    useRef
} from 'react'
import useTheme from '../../../hooks/useTheme'

export type CheckboxProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> &
    ThemeProps & {
        label?: ReactNode
        hideLabel?: boolean
        valid?: boolean
        indeterminate?: boolean
        message?: string
        labelClassName?: string
        renderLabel?: boolean
    }

const RadioButton = (
    { id, indeterminate = false, message, ...props }: CheckboxProps,
    ref: ForwardedRef<HTMLInputElement>
) => {
    const randomId = useId()
    const defaultRef = useRef<HTMLInputElement>()
    const resolvedRef = (ref || defaultRef) as MutableRefObject<HTMLInputElement>
    const { primaryColor } = useTheme()
    useEffect(() => {
        if (resolvedRef.current) {
            resolvedRef.current.indeterminate = indeterminate
        }
    }, [resolvedRef, indeterminate])

    return (
        <div className='inline-flex items-center'>
            <label className='relative flex items-center rounded-full cursor-pointer focus:outline-none'>
                <input
                    {...props}
                    id={id || randomId}
                    type='radio'
                    ref={resolvedRef}
                    className='
                    peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border-2 border-primary text-primary transition-all 
                    checked:border-primary checked:bg-transparent checked:focus:border-2 checked:focus:border-primary checked:focus:bg-transparent checked:hover:bg-transparent checked:hover:border-primary
                    hover:before:opacity-10 hover:ring-transparent 
                    focus:ring-transparent focus:border focus:border-primary focus:bg-transparent'
                />
                <span className='absolute text-blue-500 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100'>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className='h-3.5 w-3.5'
                        viewBox='0 0 16 16'
                        fill={primaryColor}
                    >
                        <circle data-name='ellipse' cx='8' cy='8' r='7'></circle>
                    </svg>
                </span>
            </label>
            {message && <span className='radio-button-message'>{message}</span>}
        </div>
    )
}

export default forwardRef(RadioButton)
