import RadioButton from '../../../base/radio-button/RadioButton'
import React, { ReactNode, useId } from 'react'

export type PriceOfferTableVariantDefaultProps = {
    selected: boolean
    variant: string
    rowId: number
    onSelect: (variant: string) => void
    label?: ReactNode
}

const PriceOfferTableDefaultVariant = ({
    selected,
    variant,
    rowId,
    onSelect,
    label
}: PriceOfferTableVariantDefaultProps) => {
    const randomId = useId()

    return (
        <label
            className='flex gap-3 hover:cursor-pointer'
            htmlFor={randomId}
            onClick={e => {
                return e.stopPropagation()
            }}
        >
            <div>
                <RadioButton
                    id={randomId}
                    className=''
                    name={`variant-row-${rowId}`}
                    checked={selected}
                    onChange={({ target: { checked } }) => {
                        if (checked) {
                            onSelect(variant)
                        }
                    }}
                />
            </div>
            <div className='pt-[2px]'>
                <span>{label}</span>
            </div>
        </label>
    )
}

export default PriceOfferTableDefaultVariant
