import { PriceOfferShort } from '../../../../services/api/price-offer/PriceOffer.types'
import { ReactNode, useMemo } from 'react'
import { Row } from '@tanstack/react-table'
import { usePriceOfferTableContext } from './PriceOfferTable.context'

export type PriceOfferTableCellProps = {
    row: Row<PriceOfferShort>
    getValue?: (value: PriceOfferShort) => ReactNode
}

const PriceOfferTableCell = ({ row: { original: priceOffer }, getValue }: PriceOfferTableCellProps) => {
    const { id, variants } = priceOffer
    const { getSelectedVariant } = usePriceOfferTableContext()

    const variant = useMemo<PriceOfferShort>(() => {
        const variant = getSelectedVariant(id, [priceOffer, ...priceOffer.variants])
        if (variant === '0') {
            return priceOffer
        }
        return variants.find(item => {
            return item.variant === variant
        })
    }, [getSelectedVariant, id, priceOffer, variants])

    return <>{getValue(variant)}</>
}

export default PriceOfferTableCell
