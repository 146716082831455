import RadioButton from '../../../base/radio-button/RadioButton'
import React, { ReactNode, useId } from 'react'

export type PriceOfferTableVariantProps = {
    selected: boolean
    variant: string
    rowId: number
    onSelect: (variant: string) => void
    label?: ReactNode
}

const PriceOfferTableVariant = ({ selected, variant, rowId, onSelect, label }: PriceOfferTableVariantProps) => {
    const randomId = useId()

    return (
        <label
            className='px-1.5 flex hover:cursor-pointer gap-3 max-w-[theme(width.64)]'
            htmlFor={randomId}
            onClick={e => {
                return e.stopPropagation()
            }}
        >
            <div>
                <RadioButton
                    id={randomId}
                    name={`variant-row-${rowId}`}
                    checked={selected}
                    onChange={({ target: { checked } }) => {
                        if (checked) {
                            onSelect(variant)
                        }
                    }}
                />
            </div>
            <div className='flex flex-col pt-[2px]'>
                <span>{label}</span>
                <span className='text-xs text-grey-600'>Varianta {variant}</span>
            </div>
        </label>
    )
}

export default PriceOfferTableVariant
