import { ColumnFilter, ColumnSort, PaginationState } from '@tanstack/react-table'
import { useState } from 'react'

export type UseCreateTableStateProps = {
    initialFilters?: ColumnFilter[]
    initialSorts?: ColumnSort[]
    initialPagination?: PaginationState
}

const useCreateTableState = ({
    initialFilters = [],
    initialSorts = [],
    initialPagination = { pageSize: 15, pageIndex: 0 }
}: UseCreateTableStateProps = {}) => {
    const [filters, setFilters] = useState<ColumnFilter[]>(initialFilters)
    const [sorts, setSorts] = useState<ColumnSort[]>(initialSorts)
    const [pagination, setPagination] = useState<PaginationState>(initialPagination)

    return {
        filters,
        setFilters,
        sorts,
        setSorts,
        pagination,
        setPagination
    }
}

export default useCreateTableState
